<template>
  <base-layout
    :filter-button="false"
  >
    <template v-slot:drawer-left>
      <div>
        <v-container class="mt-n4">
          <v-row>
            <v-col>
              <v-card>
                <v-card-title
                  class="px-0 pt-0"
                >
                  <v-tabs
                    fixed-tabs
                  >
                    <v-tab
                      @click.stop="showActiveLists()"
                    >
                      <v-icon
                        style="padding-right: 2px; padding-top: 2px;"
                        size="18"
                      >
                        mdi-file-check-outline
                      </v-icon>
                      Actief
                    </v-tab>
                    <v-tab
                      @click.stop="showArchivedLists()"
                    >
                      <v-icon
                        style="padding-right: 2px; padding-top: 2px;"
                        size="18"
                      >
                        mdi-archive-outline
                      </v-icon>
                      Archief
                    </v-tab>
                  </v-tabs>
                </v-card-title>

                <v-list
                  dense
                  flat
                >
                  <v-list-item-group>
                    <v-list-item
                      v-if="!showArchived"
                      @click.stop="newList()"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Nieuwe lijst</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                      v-for="(list, index) in activeLists()"
                      :key="index"
                      @click.stop="getList(list._id)"
                      :class="{ 'list-active': listCurrent && listCurrent.id === list.id }"
                    >
                      <v-list-item-icon>
                        <v-icon size="18">
                          mdi-format-list-bulleted
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="listName">
                          {{ list.title }}
                          <!-- Icon indicating the list was transferred to the user -->
                          <v-tooltip
                            bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                right
                                v-bind="attrs"
                                v-on="on"
                                v-if="list.latestTransfer != null"
                                class="transferredIcon">mdi-account</v-icon>
                            </template>
                            <span>{{ $t('list.receivedList') }} {{ formattedDate(list.latestTransfer) }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item
                        v-if="showArchived && activeLists() && activeLists().length === 0"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Geen lijsten aanwezig</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item> -->
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>

    <template v-slot:identity>
        <a
          href="/list-manager"
          class="d-flex align-start"
        >
        <img
            v-if="!$vuetify.theme.dark"
            style="cursor: pointer;"
            src="@/assets/polpo-logo-light.png"
            alt="Polpo - Politieke monitoring"
            height="44"
          >
          <img
            v-else
            style="cursor: pointer;"
            src="@/assets/polpo-logo-dark.png"
            alt="Polpo - Politieke monitoring"
            height="44"
          >
        </a>
      </template>

    <template v-slot:main-content>
      <div>
        <v-container fluid>
          <v-row dense>
            <!-- left -->
            <v-col
              cols="12"
              xl="10"
              lg="10"
              class="pr-2"
              v-if="listCurrent && listCurrent.title"
            >
              <v-subheader
                class="text-subtitle-1"
              >
                <div class="d-flex justify-start">
                  <span v-if="!showArchived">
                    Actief
                  </span>
                  <span v-else>
                    Archief
                  </span>
                </div>
              </v-subheader>

              <v-card>
                <v-card-title>
                  <v-row>
                    <v-col class="py-2">
                      {{ listCurrent.title }}
                    </v-col>
                    <v-col class="d-flex justify-end align-center ma-0 py-0">
                      <v-tooltip
                        :disabled="$vuetify.breakpoint.mdAndDown"
                        open-delay="1000"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click.stop="confirmDuplicateList()"
                            class="mr-2"
                            icon
                            color="orange"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Lijst dupliceren</span>
                      </v-tooltip>

                      <v-tooltip
                        :disabled="$vuetify.breakpoint.mdAndDown"
                        open-delay="1000"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click.stop="editList()"
                            class="mr-2"
                            icon
                            color="orange"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-square-edit-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Lijst bewerken</span>
                      </v-tooltip>

                      <v-tooltip
                        :disabled="$vuetify.breakpoint.mdAndDown"
                        open-delay="1000"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click.stop="confirmClearList()"
                            class="mr-2"
                            icon
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-eraser</v-icon>
                          </v-btn>
                        </template>
                        <span>Lijst leegmaken</span>
                      </v-tooltip>

                      <v-tooltip
                        :disabled="$vuetify.breakpoint.mdAndDown"
                        open-delay="1000"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="red"
                            class="mr-2"
                            @click.stop="confirmRemoveList()"
                            :loading="removeBusy"
                            :disabled="removeDisabled"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Lijst verwijderen</span>
                      </v-tooltip>
                      <v-divider
                        inset
                        vertical
                      ></v-divider>
                      <v-tooltip
                        :disabled="$vuetify.breakpoint.mdAndDown"
                        open-delay="1000"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-3"
                            fab
                            :outlined="!listCurrent.archived"
                            :color="listCurrent.archived ? 'teal lighten-1' : 'grey'"
                            :dark="listCurrent.archived"
                            small
                            @click.stop="toggleArchived()"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-archive-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Lijst archiveren</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text v-if="listCurrent.description">
                  {{ listCurrent.description }}
                </v-card-text>

                <v-card-text class="mb-n5">
                  <v-row class="d-flex align-center">
                    <v-col class="text-subtitle-1">
                      <span class="mr-2">
                        Updates ({{ totalItemsMarkedForExport }})
                      </span>
                      <v-btn
                        text
                        class="text--secondary"
                        @click.stop="sortOnDate"
                      >
                        Sorteer op datum
                        <v-icon v-if="sorted">{{ reverseSortOrder ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        class="mr-2"
                        @click.stop="showListManagerItemForm()"
                        outlined
                        color="teal lighten-2"
                      >
                        Eigen update aanmaken
                      </v-btn>
                      <div v-if="authHasModuleAccess('MC04', { dataExportEnabled: true })">
                        <v-tooltip
                          :disabled="!((authHasModuleAccess('MC04', { dataExportEnabled: true })
                            && totalItemsMarkedForExport === 0))"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-btn
                                class="mr-2"
                                :disabled="!(authHasModuleAccess('MC04',
                                  { dataExportEnabled: true })
                                  && totalItemsMarkedForExport >= 1)"
                                @click.stop="showPreviewFromExportHTML()"
                                outlined
                                color="teal lighten-2"
                              >
                                Bekijk nieuwsbrief preview
                              </v-btn>
                            </div>
                          </template>
                          <span>
                            0 updates geselecteerd voor nieuwsbrief preview
                          </span>
                        </v-tooltip>
                      </div>

                      <div v-if="authHasModuleAccess('MC04', { dataExportEnabled: true })">
                        <v-tooltip
                          :disabled="!((authHasModuleAccess('MC04', { dataExportEnabled: true })
                            && totalItemsMarkedForExport === 0))"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-btn
                                :disabled="!(authHasModuleAccess('MC04',
                                  { dataExportEnabled: true })
                                  && totalItemsMarkedForExport >= 1)"
                                @click.stop="copyExportHtmlToClipboard()"
                                outlined
                                color="teal lighten-2"
                              >
                                {{ totalItemsMarkedForExportString }}
                              </v-btn>
                            </div>
                          </template>
                          <span>
                            0 updates geselecteerd voor kopiëren als nieuwsbrief
                          </span>
                        </v-tooltip>
                      </div>

                      <div v-if="authHasModuleAccess('MC04', { dataExportEnabled: false })">
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-btn
                                :disabled="!(authHasModuleAccess('MC04',
                                  { dataExportEnabled: true })
                                  && totalItemsMarkedForExport >= 1)"
                                @click.stop="copyExportHtmlToClipboard()"
                                outlined
                                color="teal lighten-2"
                              >
                                {{ totalItemsMarkedForExportString }}
                              </v-btn>
                            </div>
                          </template>
                          <span>
                            Deze functionaliteit is onderdeel van de nieuwsbriefmodule. Interesse? Neem contact met ons op. We helpen je graag verder.
                          </span>
                        </v-tooltip>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>

                <v-container fluid>
                  <v-card v-if="totalItemsInList == 0 && !globalLoaderBusy">
                    <v-card-text>
                      <p>
                        Er zijn nog geen updates aan deze lijst toegevoegd.
                        <br>
                        <br>Druk op
                        <v-btn
                          outlined
                          color="teal"
                          dark
                        >
                          + Lijst
                        </v-btn> op de tijdlijn om updates aan deze lijst toe te voegen.
                      </p>
                    </v-card-text>
                  </v-card>

                  <transition-group name="list">
                    <template>
                      <list-manager-item
                        v-for="(listItem, index) in checkCurrentListForDuplicates"
                        :key="listItem.listId + listItem.id"
                        :listId="listItem.listId"
                        :index="index"
                        :data="listItem"
                        :total-items="checkCurrentListForDuplicates.length"
                        :toggleExport="() => toggleExport(listItem)"
                      />
                    </template>
                  </transition-group>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
    <template v-slot:drawer-right>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn
            class="mr-2 mt-4"
            :disabled="transferDisabled"
            @click.stop="showListManagerTransferForm()"
            outlined
            color="teal lighten-2"
          >
            {{ $t('list.transfer.transferLists') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </base-layout>
</template>

<script>
import { mapGetters } from 'vuex';

// App specific
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import EventBus from '@/event-bus';
import { showDialog } from '@/helpers/dialogHelper';

import ServerConfig from '@/config.server';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import listApi from '../api/list-api';
import ListManagerItem from '../components/ListManagerItem.vue';
import ListManagerForm from '../components/ListManagerForm.vue';
import ListManagerItemForm from '../components/ListManagerItemForm.vue';
import ListManagerTransferForm from '../components/ListManagerTransferForm.vue';

export default {
  name: 'ListManager',
  components: {
    BaseLayout,
    ListManagerItem,
  },

  data() {
    return {
      ListManagerOutput: null,
      backButton: true,
      sorted: false,
      reverseSortOrder: false,
      showArchived: false,
      referrer: false,
      removeBusy: false,
      removeDisabled: false,
      formData: null,
      formVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      listsActive: 'listsActive',
      listsArchived: 'listsArchived',
      listCurrent: 'listCurrent',
      listCurrentItems: 'listCurrentItems',
      globalLoaderBusy: 'globalLoaderBusy',
    }),

    transferDisabled() {
      return ((!this.listsActive || this.listsActive.length === 0)
        && (!this.listsArchived || this.listsArchived.length === 0));
    },
    totalItemsInList() {
      return this.listCurrentItems.length;
    },
    totalItemsMarkedForExport() {
      return this.listCurrentItems.filter((item) => item.markedForExport && !item.isSubHeader)
        .length;
    },
    totalItemsMarkedForExportString() {
      if (this.totalItemsMarkedForExport === 0) {
        return 'Kopiëren als nieuwsbrief';
      }
      return `${this.totalItemsMarkedForExport} Update${this.totalItemsMarkedForExport > 1 ? 's' : ''} kopiëren als nieuwsbrief`;
    },
    checkCurrentListForDuplicates() {
      this.listCurrentItems.forEach((obj, index) => {
        const indexOfDuplicate = this.listCurrentItems.findLastIndex(
          (item) => item.title === obj.title
            && item.document?.id === obj.document?.id,
        );
        if (indexOfDuplicate != null && indexOfDuplicate !== index) obj.duplicate = true;
      });
      return this.listCurrentItems;
    },
  },

  methods: {
    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);

      this.$gtag.event('list-manager-show-teaser', {
        event_category: 'list-manager',
      });
    },
    confirmClearList() {
      EventBus.$on('submitConfirmDialog', this.clearList);
      this.$store.dispatch('setConfirmDialogHeader', 'Lijst leegmaken');
      this.$store.dispatch(
        'setConfirmDialogBody',
        'Weet je zeker dat je deze lijst wilt leegmaken?',
      );
      this.$store.dispatch('setConfirmDialogVisible', true);

      this.$gtag.event('list-manager-list-clear-confirm', {
        event_category: 'list-manager',
      });
    },
    async clearList() {
      this.busyRemove = true;
      this.disabled = true;

      await this.$store.dispatch('clearCurrentList');
      this.busyRemove = false;
      this.disabled = false;

      EventBus.$off('submitConfirmDialog');

      this.$gtag.event('list-manager-list-clear', {
        event_category: 'list-manager',
      });
    },
    confirmRemoveList() {
      EventBus.$on('submitConfirmDialog', this.removeList);
      this.$store.dispatch('setConfirmDialogHeader', 'Lijst verwijderen');
      this.$store.dispatch(
        'setConfirmDialogBody',
        'Weet je zeker dat je deze lijst wilt verwijderen?',
      );
      this.$store.dispatch('setConfirmDialogVisible', true);

      this.$gtag.event('list-manager-list-remove-confirm', {
        event_category: 'list-manager',
      });
    },
    async removeList() {
      this.busyRemove = true;
      this.disabled = true;

      await this.$store.dispatch('removeCurrentList');
      this.busyRemove = false;
      this.disabled = false;

      this.$store.commit('setCurrentList');

      EventBus.$off('submitConfirmDialog');

      this.$gtag.event('list-manager-list-remove', {
        event_category: 'list-manager',
      });
    },
    async toggleExport(listItem) {
      await this.$store.dispatch('toggleListItemMarkedForExport', listItem);
      this.$gtag.event('list-manager-item-toggle-export', {
        event_category: 'list-manager',
      });
    },
    confirmDuplicateList() {
      EventBus.$on('submitConfirmDialog', this.duplicateList);
      this.$store.dispatch('setConfirmDialogHeader', 'Lijst dupliceren');
      this.$store.dispatch(
        'setConfirmDialogBody',
        'Weet je zeker dat je deze lijst wilt dupliceren?',
      );
      this.$store.dispatch('setConfirmDialogVisible', true);

      this.$gtag.event('list-manager-list-duplicate-confirm', {
        event_category: 'list-manager',
      });
    },
    async duplicateList() {
      this.busyRemove = true;
      this.disabled = true;
      const duplicatedListId = await this.$store.dispatch('duplicateCurrentList');
      this.getList(duplicatedListId);
      this.busyRemove = false;
      this.disabled = false;

      EventBus.$off('submitConfirmDialog');

      this.$gtag.event('list-manager-list-duplicate', {
        event_category: 'list-manager',
      });
    },
    newList() {
      showDialog(ListManagerForm, {
        title: null,
        description: null,
      });

      this.showActiveLists();

      this.$gtag.event('list-manager-list-new', {
        event_category: 'list-manager',
      });
    },
    editList() {
      showDialog(ListManagerForm, { ...this.listCurrent });
      this.$gtag.event('list-manager-list-edit', {
        event_category: 'list-manager',
      });
    },
    async showArchivedLists() {
      if (!this.showArchived) {
        await this.$store.dispatch('setDefaultListState');
        this.showArchived = true;
        await this.$store.dispatch('getArchivedLists');

        this.$gtag.pageview({
          page_path: '/list-manager/archived',
        });
      }
    },
    async showActiveLists() {
      if (this.showArchived) {
        await this.$store.dispatch('setDefaultListState');
        this.showArchived = false;
        await this.$store.dispatch('getActiveLists');

        this.$gtag.pageview({
          page_path: '/list-manager/active',
        });
      }
    },
    activeLists() {
      return this.showArchived ? this.listsArchived : this.listsActive;
    },
    async getList(id) {
      await this.$store.dispatch('setDefaultListState');
      this.$gtag.pageview({
        page_path: `/list-manager/${id}`,
      });
      await this.$store.dispatch('getList', id);
    },
    async showPreviewFromExportHTML() {
      const listId = this.listCurrent.id;
      const htmlPreviewToken = await this.$store.dispatch(
        'getListExportHTMLPreviewToken',
      );

      this.$gtag.pageview({
        page_path: `/list-manager/${listId}/preview-export-html`,
      });
      this.$gtag.event('list-manager-list-preview-export-html', {
        event_category: 'list-manager',
      });

      window.open(
        `${ServerConfig.address}/lists/${listId}/export/html/${htmlPreviewToken}`,
        '_blank',
      );
    },
    async copyExportHtmlToClipboard() {
      const listId = this.listCurrent.id;
      const htmlPreviewToken = await this.$store.dispatch(
        'getListExportHTMLPreviewToken',
      );

      this.$gtag.pageview({
        page_path: `/list-manager/${listId}/export-html`,
      });
      this.$gtag.event('list-manager-list-export-html', {
        event_category: 'list-manager',
      });

      const output = await listApi.getHTMLExport(listId, htmlPreviewToken);

      if (output) {
        const { data: rawHTML } = output;

        const type = 'text/html';
        const blob = new Blob([rawHTML], { type });
        const data = [new window.ClipboardItem({ [type]: blob })];
        await navigator.clipboard.write(data);

        setTimeout(() => {
          this.$store.dispatch('setMessage', {
            message: 'Nieuwsbrief gekopieerd naar klembord',
            type: 'info',
          });
        }, 300);
      }
    },
    selectedListId() {
      return this.listCurrent ? this.listCurrent.id : null;
    },
    async toggleArchived() {
      await this.$store.dispatch('toggleListArchive');

      this.$gtag.event('list-manager-list-toggle-archive', {
        event_category: 'list-manager',
      });
    },
    showListManagerItemForm() {
      showDialog(ListManagerItemForm, {
        createCustom: true,
        customDocument: {},
        lists: [this.listCurrent._id],
        list: [this.listCurrent._id],
      });

      this.$gtag.event('list-manager-item-create-custom-item', {
        event_category: 'list-manager',
      });
    },

    async showListManagerTransferForm() {
      await this.$store.dispatch('getActiveLists', false);
      await this.$store.dispatch('getArchivedLists', false);
      showDialog(ListManagerTransferForm, {
        listsActive: this.listsActive,
        listsArchived: this.listsArchived,
      });

      this.$gtag.event('list-manager-transfer', {
        event_category: 'list-manager',
      });
    },

    formattedDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    async sortOnDate() {
      this.sorted = true;
      this.reverseSortOrder = !this.reverseSortOrder;
      if (!this.reverseSortOrder) {
        this.$store.dispatch('sortListOnDate', { listId: this.listCurrent.id, desc: true });
      } else {
        this.$store.dispatch('sortListOnDate', { listId: this.listCurrent.id, desc: false });
      }
    },
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: '/list-manager',
    });
    this.$store.dispatch('getActiveLists');
    await this.$store.dispatch('getDocumentFilterTypesForUser');
  },
};
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
  .v-tab {
    letter-spacing: .0em !important;
    text-transform: none !important;
  }
  // // ANIMATIONS
  .list-enter-active,
  .list-leave-active {
    transition: all 0.3s;
  }
  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateX(101%);
    // height: 0;
  }
  .list-move {
    transition: transform 0.4s ease-out;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-in-out;
  }
  .fade-enter,
  .fade-leave-to {
    transform: scale(0);
    opacity: 0;
    height: 0;
  }
  .listName {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list-active {
    .v-list-item__title {
      color: #009688;
    }
    .v-list-item__icon {
      .v-icon {
        color: #009688;
      }
    }
  }
</style>
